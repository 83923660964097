angular.module('fingerink')
    .directive('avanzadaSign', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-sign.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            link: function ($scope, element, attrs) {
                $scope.bounds = element[0].getBoundingClientRect();
            },
            controllerAs: 'controller',
            controller: 'avanzadaSignCtrl'
        };
    })
    .controller('avanzadaSignCtrl', function ($rootScope, $scope, $uibModal, $window, $translate, $timeout, signModal) {
        var that = this;

        $scope.mostrar = function () {
            if ($scope.fillable) {
                signModal.openModal().result.then((result) => $scope.data.answer = result || $scope.data.answer);
            }
        };

        $scope.$watch('fillable', () =>{
            if($scope.fillable){
                that.unregister = $scope.$watch('data.signer.signType', () => $scope.data.answer = null);
            }else{
                if(that.unregister){
                    that.unregister();
                    $scope.data.answer = null;
                    that.unregister = null;
                }
            }
        });
        
        $scope.$on('call' + $scope.data.id, () => $timeout($scope.mostrar));

    });
